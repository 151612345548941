@import "styles/mixins";
@import "styles/variables";


.root {
    &:global.bg-white {
        .formio-component {
            .formio-form {
                a {
                    font-weight: 500;
                    text-decoration: underline;

                    &:hover,
                    &:focus,
                    &:active {
                        text-decoration: none;
                    }
                }

                input,
                textarea {
                    border: 1px solid $bg-light;
                }
            }
        }
    }
}