@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        background: linear-gradient(180deg, #717C7D -33.96%, rgba(255, 255, 255, 0.00) 100%);
        @include border-radius(25px 25px 0 0);

        .dialog-content {
            display: none;
        }

    }
}