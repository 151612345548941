@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        .swiper-slide {
            height: auto;
        }

        .swiperArrowWrapper {
            right: 0;
            top: 0;

            button {
                background: transparent;
            }

            svg {
                width: 20px;
                height: 20px;
            }
        }

        .card {
            background: $primary-color;
            color: $base-white;
            border: 4px solid $primary-color;
            @include border-radius(25px);

            a {

                &:hover,
                &:focus,
                &:active {
                    color: $base-white;

                    .post-title {
                        color: $base-white;
                        opacity: 0.5;
                    }
                }
            }
        }

        .card-img {
            object-fit: cover;
        }

        .post-description {
            @include line-clamp(14px, 1.2, 4);
        }

        .post-title {
            @include line-clamp(18px, 1.2, 2);
            @include trans;
        }

        .post-tag {
            top: 15px;
            margin: 0 15px;
        }

    }
}