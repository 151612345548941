@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        background: $base-black;
        color: $base-white;
        position: relative;

        .js-panel-border {
            border: none !important;
        }

        .contentWrapper {
            margin-top: 40px;
            padding: 10rem 0;
            min-height: 800px;
            z-index: 1;
        }

        .imageWrapper {
            right: 0;
            top: 0;
            bottom: 0;
            width: 60%;

            .card-img {
                width: 100%;
                height: 100%;
                @include object-fit;
            }

            svg {
                height: 100%;
                width: 100%;
            }
        }

        .mainImageWrapper {
            z-index: 1;
            padding: 11rem 4rem;

            

            .card-img {
                width: 100%;
                height: 100%;
                @include object-fit;
            }
        }

        .js-container {
            margin: 3rem 0;

            .js-form-input-wrapper {
                background: $base-black;
                @include flex;
                @include border-radius(50px);
                padding: 0.5rem 0.5rem 0.5rem 2rem;
                border: 1px solid $base-white;

                .js-input-wrapper-query {
                    padding-right: 1rem;
                    width: 100%;
                    @include flex;
                    @include align-items(center);

                    input {
                        outline: none;
                        width: 100%;
                        background: transparent;
                        color: $base-white;
                        border: none;
                    }
                }

                .js-button {
                    white-space: nowrap;
                    @include primaryBtn;
                    margin: 0;
                    border: none;

                    &:after {
                        content: none;
                    }
                }
            }
        }

        .banner-anchor {
            z-index: 1;
            margin-left: -0.5rem;
            bottom: 50px;
            background: $primary-color;
            padding: 0.6rem 0.875rem;
            cursor: pointer;
            @include trans;
            @include border-radius(50px);

            svg {
                width: 20px;
                height: 20px;

                path {
                    fill: $base-white;
                }
            }

            &:hover,
            &:focus,
            &:active {
                opacity: 0.5;
            }
        }

        @media (max-width: $breakpoint-lg) {
            .contentWrapper {
                margin-top: 0px;
                padding: 12rem 0 0 0;
                min-height: auto;
            }

            .mainImageWrapper {
                padding: 0 0 10rem 0;
            }

            .imageWrapper {
                top: auto;
                width: 100%;
            }
        }
    }

    &:global:before {
        content: "";
        background: linear-gradient(90deg, #000 67.66%, rgba(0, 0, 0, 0.00) 100%);
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        bottom: 0;
        width: 60%;
    }

    &:global.hidden-image-1 {
        .contentWrapper {
            padding: 10rem 0 5rem 0;
            min-height: 500px;
        }
    }
}