@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        .left-column {
            // @include border-radius(25px 25px 0 0);
            // background: linear-gradient(180deg, $primary-color 0%, rgba(255, 204, 51, 0.00) 100%);
            background: $base-black;
            color: $base-white;
        }

        .right-column {
            // background: linear-gradient(180deg, $tertiary-color 0%, #9CC 0.01%, rgba(153, 204, 204, 0.00) 100%);
            background: $secondary-color;

            .card {

                &:hover,
                &:focus,
                &:active {
                    a {

                        background: $primary-color;
                        color: $base-white;

                    }
                }
            }
        }

        .card {
            a {
                border: 1px solid $primary-color;
                background: transparent;
                @include trans;
            }

            &:hover,
            &:focus,
            &:active {
                a {

                    background: $primary-color;
                    color: $base-white;

                }
            }
        }

        @media (min-width: $breakpoint-md) {
            // .left-column {
            //     @include border-radius(25px 0 0 0);
            // }

            // .right-column {
            //     @include border-radius(0 25px 0 0);
            // }
        }
    }
}